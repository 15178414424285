import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';

import DAOFeaturesGrid from '../DAOFeaturesGrid';
import { SectionInnerRow } from '../Rows';
import SimpleInnerSection from '../SimpleInnerSection';

const CommunityPageDAOSection = () => {
  const { data } = useStaticQuery(query);
  const {
    daoSection: { title, description }
  } = data.edges[0].node;

  return (
    <>
      <SectionInnerRow justify="center">
        <Col lg={6}>
          <SimpleInnerSection title={title} text={description} />
        </Col>
      </SectionInnerRow>
      <SectionInnerRow>
        <Col>
          <DAOFeaturesGrid />
        </Col>
      </SectionInnerRow>
    </>
  );
};

const query = graphql`
  query {
    data: allCommunityPageYaml {
      edges {
        node {
          daoSection {
            title
            description
          }
        }
      }
    }
  }
`;

export default CommunityPageDAOSection;
