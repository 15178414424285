import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import styled from 'styled-components';

import deviceBreakPoints from '../../styles/breakpoints';
import { renderIcon } from '../../utils/icons';
import Button from '../Button';
import { Community, CommunityButton } from '../CommunitiesRow';

let CommunityPageCommunitiesGrid: FC<{ className?: string }> = ({ className }) => {
  const { data } = useStaticQuery(query);
  const communities = data.edges.map((edge: { node: Community }) => edge.node);

  return (
    <div className={className}>
      {communities.map(({ name, url, icon }: Community) => (
        <div key={name}>
          <Button
            primary
            to={url}
            newTab={!!url && !url.includes('mailto')}
            disabled={!url}
            caption={!url ? 'coming soon' : undefined}
          >
            <CommunityButton>
              {renderIcon(icon)}
              {name}
            </CommunityButton>
          </Button>
        </div>
      ))}
    </div>
  );
};

CommunityPageCommunitiesGrid = styled(CommunityPageCommunitiesGrid)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--spacing-3);
  row-gap: var(--spacing-4);

  @media ${deviceBreakPoints.tablet} {
    grid-template-columns: repeat(2, 1fr);
  }

  @media ${deviceBreakPoints.mobile} {
    grid-template-columns: repeat(1, 1fr);
  }

  img {
    width: 100%;
  }
`;

const query = graphql`
  query {
    data: allCommunitiesYaml {
      edges {
        node {
          name
          url
          icon
        }
      }
    }
  }
`;

export default CommunityPageCommunitiesGrid;
