import { FC } from 'react';
import styled, { css } from 'styled-components';

import Caption from './Caption';
import Markdown from './Markdown';

export interface DiagramProps {
  className?: string;
  image: { publicURL: string };
  imageAltText: string;
  caption?: string;
  header?: string;
  topCaption?: string;
  maxImageWidth?: string;
}

let Diagram: FC<DiagramProps> = ({ className, image: { publicURL }, header, caption, topCaption, imageAltText }) => {
  return (
    <div className={className}>
      {topCaption && <TopCaption>{topCaption}</TopCaption>}
      {header && <Header>{header}</Header>}
      <img src={publicURL} alt={imageAltText} />
      {caption && (
        <BottomCaption>
          <MarkdownStyled content={caption} />
        </BottomCaption>
      )}
    </div>
  );
};

Diagram = styled(Diagram)`
  background-color: ${({ theme }) => theme.bg.secondary};
  padding: var(--spacing-4) var(--spacing-2);
  height: 100%;
  box-sizing: border-box;

  ${({ topCaption }) =>
    topCaption &&
    css`
      padding-top: var(--spacing-2);
    `}

  ${({ caption }) =>
    caption &&
    css`
      padding-bottom: var(--spacing-2);
    `}

  img {
    width: 100%;

    ${({ maxImageWidth }) =>
      maxImageWidth &&
      css`
        max-width: ${maxImageWidth};
        display: block;
        margin: 0 auto;
      `};
  }
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: var(--spacing-5);
`;

const ColoredCaption = styled(Caption)`
  color: ${({ theme }) => theme.text.secondary};
`;

const TopCaption = styled(ColoredCaption)`
  margin-bottom: var(--spacing-4);
`;

const BottomCaption = styled(ColoredCaption)`
  margin-top: var(--spacing-4);
`;

const MarkdownStyled = styled(Markdown)`
  ul {
    li {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        margin-bottom: 3px;
      }

      &:before {
        content: '';
        width: 33px;
        height: 4px;
        margin-right: var(--spacing-2);
        display: inline-block;
      }

      &:nth-child(1):before {
        background-color: var(--color-blue-800);
      }

      &:nth-child(2):before {
        background-color: var(--color-red-800);
      }

      &:nth-child(3):before {
        background-color: var(--color-green);
      }
    }
  }
`;

export default Diagram;
