import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import CaptionedTitle from './CaptionedTitle';
import Diagram, { DiagramProps } from './Diagram';
import Heading from './Heading';
import Markdown from './Markdown';
import { BlockRow, OrganismRow, SectionInnerRow } from './Rows';

interface SimpleInnerSectionProps {
  title: string;
  text: string;
  diagrams?: DiagramProps[];
  maxImageWidths?: string[];
  id?: string;
  className?: string;
  captioned?: boolean;
  caption?: string;
}

const SimpleInnerSection: FC<SimpleInnerSectionProps> = ({
  title,
  text,
  diagrams = [],
  maxImageWidths,
  id,
  className,
  children,
  captioned = false,
  caption
}) => (
  <SectionInnerRow id={id} className={className}>
    <Col>
      {captioned ? (
        <CaptionedTitle as="h2" styledAs="h3" caption={caption}>
          {title}
        </CaptionedTitle>
      ) : (
        <Heading2 element="h2" styledAs="h3">
          {title}
        </Heading2>
      )}
      <BlockRow>
        <Col>
          <Markdown content={text} />
          {diagrams.map((diagram, index) => (
            <OrganismRow key={diagram.header || diagram.imageAltText}>
              <Col>
                <Diagram {...diagram} maxImageWidth={maxImageWidths && maxImageWidths[index]} />
              </Col>
            </OrganismRow>
          ))}
          {children}
        </Col>
      </BlockRow>
    </Col>
  </SectionInnerRow>
);

const Heading2 = styled(Heading)`
  margin: 0;
  border-bottom: 1px solid ${({ theme }) => theme.text.primary};
`;

export default SimpleInnerSection;
