import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import { renderIcon } from '../utils/icons';
import Button from './Button';
import { SectionInnerRow } from './Rows';

export interface Community {
  name: string;
  url?: string;
  icon: string;
}

let CommunitiesRow: FC<{ className?: string }> = ({ className }) => {
  const { data } = useStaticQuery(query);
  const communities = data.edges.map((edge: { node: Community }) => edge.node);

  return (
    <SectionInnerRow className={`scrollable full-width-mobile ${className}`}>
      {communities.map(({ name, url, icon }: Community) => (
        <Col lg={2} xl={3} key={name}>
          <Button
            primary
            to={url}
            newTab={!!url && !url.includes('mailto')}
            disabled={!url}
            caption={!url ? 'coming soon' : undefined}
          >
            <CommunityButton>
              {renderIcon(icon)}
              {name}
            </CommunityButton>
          </Button>
        </Col>
      ))}
    </SectionInnerRow>
  );
};

CommunitiesRow = styled(CommunitiesRow)`
  padding-top: 5px;
  padding-bottom: 17px;
`;

export const CommunityButton = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  svg {
    margin: 0;
  }
`;

const query = graphql`
  query {
    data: allCommunitiesYaml {
      edges {
        node {
          name
          url
          icon
        }
      }
    }
  }
`;

export default CommunitiesRow;
