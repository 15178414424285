import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import styled from 'styled-components';

import deviceBreakPoints from '../styles/breakpoints';

type DaoFeature = {
  image: {
    publicURL: string;
  };
  text: string;
};

let DAOFeaturesGrid: FC<{ className?: string }> = ({ className }) => {
  const { data } = useStaticQuery(query);
  const daoFeatures = data.edges.map((edge: { node: DaoFeature }) => edge.node);

  return (
    <div className={className}>
      {daoFeatures.map(({ image: { publicURL }, text }: DaoFeature) => (
        <div key={publicURL}>
          <img src={publicURL} alt={text} />
          <Caption>{text}</Caption>
        </div>
      ))}
    </div>
  );
};

DAOFeaturesGrid = styled(DAOFeaturesGrid)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: var(--spacing-3);
  row-gap: var(--spacing-5);

  @media ${deviceBreakPoints.tablet} {
    grid-template-columns: repeat(3, 1fr);
  }

  @media ${deviceBreakPoints.mobile} {
    grid-template-columns: repeat(2, 1fr);
  }

  img {
    width: 100%;
  }
`;

const Caption = styled.div`
  margin-top: var(--spacing-2);
`;

const query = graphql`
  query {
    data: allDaoFeaturesYaml {
      edges {
        node {
          image {
            publicURL
          }
          text
        }
      }
    }
  }
`;

export default DAOFeaturesGrid;
