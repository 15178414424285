import { graphql, useStaticQuery } from 'gatsby';
import { Container } from 'react-awesome-styled-grid';
import { ThemeProvider } from 'styled-components';

import CommunityPageDAOSection from '../components/community-page/CommunityPageDAOSection';
import CommunityPageHeader from '../components/community-page/CommunityPageHeader';
import Page from '../components/Page';
import ThemedBackground from '../components/ThemedBackground';
import { darkTheme, lightTheme } from '../styles/themes';

const CommunityPage = () => {
  const { data } = useStaticQuery(query);
  const { seo } = data.edges[0].node;

  return (
    <Page seoTitle={seo.title} seoDescription={seo.description}>
      <ThemeProvider theme={lightTheme}>
        <CommunityPageHeader />
      </ThemeProvider>
      <ThemeProvider theme={darkTheme}>
        <ThemedBackground pt>
          <Container>
            <CommunityPageDAOSection />
          </Container>
        </ThemedBackground>
      </ThemeProvider>
    </Page>
  );
};

const query = graphql`
  query {
    data: allCommunityPageYaml {
      edges {
        node {
          seo {
            title
            description
          }
        }
      }
    }
  }
`;

export default CommunityPage;
