import { graphql, useStaticQuery } from 'gatsby';
import { FC } from 'react';
import { Col, Row } from 'react-awesome-styled-grid';
import styled from 'styled-components';

import deviceBreakPoints from '../../styles/breakpoints';
import CaptionedTitle from '../CaptionedTitle';
import Markdown from '../Markdown';
import PageHeader from '../PageHeader';
import CommunityPageCommunitiesGrid from './CommunityPageCommunitiesGrid';
import CommunityPageOverviewDiagram from './CommunityPageOverviewDiagram';

const Header: FC = () => {
  const { data } = useStaticQuery(query);
  const {
    header: { title, caption, description }
  } = data.edges[0].node;

  return (
    <PageHeader>
      <Row>
        <Col lg={7}>
          <CaptionedTitle as="h2" caption={caption}>
            {title}
          </CaptionedTitle>
        </Col>
      </Row>
      <RowStyled>
        <ColStyled lg={5}>
          <Markdown content={description} />
        </ColStyled>
        <Col lg={6} offset={{ lg: 1 }}>
          <CommunityPageCommunitiesGrid />
        </Col>
      </RowStyled>
      <CommunityPageOverviewDiagram />
    </PageHeader>
  );
};

const RowStyled = styled(Row)`
  margin-top: var(--spacing-2);

  @media ${deviceBreakPoints.desktop} {
    gap: var(--spacing-5);
  }
`;

const ColStyled = styled(Col)`
  align-self: flex-end;
`;

const query = graphql`
  query {
    data: allCommunityPageYaml {
      edges {
        node {
          header {
            title
            caption
            description
          }
        }
      }
    }
  }
`;

export default Header;
