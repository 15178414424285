import { graphql, useStaticQuery } from 'gatsby';
import { Col } from 'react-awesome-styled-grid';

import BorderedBox from '../BorderedBox';
import CaptionedTitle from '../CaptionedTitle';
import Diagram from '../Diagram';
import { BlockRow, SectionRow } from '../Rows';

const CommunityPageOverviewDiagram = () => {
  const { data } = useStaticQuery(query);
  const {
    overviewSection: { title, diagram }
  } = data.edges[0].node;

  return (
    <SectionRow>
      <Col>
        <CaptionedTitle as="h2" styledAs="h3">
          {title}
        </CaptionedTitle>
        <BlockRow>
          <Col>
            <BorderedBox>
              <Diagram {...diagram} maxImageWidth="610px" />
            </BorderedBox>
          </Col>
        </BlockRow>
      </Col>
    </SectionRow>
  );
};

const query = graphql`
  query {
    data: allCommunityPageYaml {
      edges {
        node {
          overviewSection {
            title
            diagram {
              image {
                publicURL
              }
              caption
            }
          }
        }
      }
    }
  }
`;

export default CommunityPageOverviewDiagram;
